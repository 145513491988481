const AuthRequest = {
  async getRequest(requestId) {
    console.log("fetching Auth Request", requestId)
    const init = {
      credentials: 'same-origin',
    };
    const resp = await fetch("/api/auth/" + requestId, init)
    if (resp.status != 200) {
      throw new Error("An error occurred while processing the authentication request.");
    }
    return await resp.json();
  },

  async login(requestId, params) {
    const init = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params),
      credentials: 'same-origin',
    };
    const resp = await fetch('/api/auth/' + requestId + '/login', init);
    let data;
    switch (resp.status) {
      case 200:
        data = await resp.json();
        window.location = data.location;
        return null;

      case 403:
        data = await resp.json();
        throw new Error(data.error);

      default:
        throw new Error("An error occurred while processing your login.");
    }
  },

  async selectAccount(requestId, params) {
    const init = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params),
      credentials: 'same-origin',
    };
    const resp = await fetch('/api/auth/' + requestId + '/select', init);
    let data;
    switch (resp.status) {
      case 200:
        data = await resp.json();
        window.location = data.location;
        return null;

      default:
        throw new Error("An error occurred while processing your login.");
    }
  },

  async getLogoutRequest(requestId) {
    console.log("fetching Logout Request", requestId)
    const init = {
      credentials: 'same-origin',
    };
    const resp = await fetch("/api/logout/" + requestId, init)
    if (resp.status != 200) {
      throw new Error("An error occurred while processing the logout request.");
    }
    return await resp.json();
  },

  async logout(requestId, params) {
    const init = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params),
      credentials: 'same-origin',
    };
    const resp = await fetch('/api/logout/' + requestId, init);
    let data;
    switch (resp.status) {
      case 200:
        return await resp.json();

      case 403:
        data = await resp.json();
        throw new Error(data.error);

      default:
        throw new Error("An error occurred while processing your login.");
    }
  },

};


export default AuthRequest;
