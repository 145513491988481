import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  Router as Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from 'react-router-dom'
import createHistory from "history/createBrowserHistory";

import { AuthProvider, withAuthContext } from './Auth.js';
import ErrorBoundary from './ErrorBoundary.js';
import ErrorPage from './ErrorPage.js';
import Home from './Home.js';
import LoadingPage from './LoadingPage.js';
import Login from './Login.js';
import Logout from './Logout.js';
import SelectAccount from './SelectAccount.js';
import UserMain from './UserMain.js';

import * as Constants from '../Constants.js';


const history = createHistory();


const Routes = withRouter(withAuthContext(class extends React.Component {
  render() {
    const { auth } = this.props;
    if (auth.error) {
      return <ErrorPage error={auth.error} />
    }
    if (auth.isPending) {
      return <LoadingPage />
    }
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login/:requestId" component={Login} />
        <Route path="/logout/:requestId" component={Logout} />
        <Route path="/select-account/:requestId" component={SelectAccount} />
        <Route path="/user" component={UserMain} />
      </Switch>
    );
  }
}))


class App extends React.Component {
  render() {
    const { theme, userManager } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <AuthProvider
            issuer={Constants.oidc_issuer}
            clientId={Constants.oidc_client_id}
            onLoginSuccess={() => {history.replace('/user');}}
            onLogoutSuccess={() => {history.replace('/');}}
          >
            <Router history={history}>
              <Routes />
            </Router>
          </AuthProvider>
        </ErrorBoundary>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default App;
