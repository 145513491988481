import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import AppBar from './AppBar.js';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  main: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 10,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});


function Layout(props) {
  const { children, classes, theme, user } = props;

  return (
    <React.Fragment>
      <AppBar user={user} />
      <div className={classes.root}>
        <main className={classes.main}>
          <Grid container spacing={24} justify="center">
            <Grid item xs={12} sm={10} md={8}>

              { children }

            </Grid>
          </Grid>
        </main>
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);
