import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Layout from './MiniLayout.js';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Layout>
          <Typography variant="title">Error</Typography>
          <Typography>Something went wrong.</Typography>
        </Layout>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
