import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Layout from './MiniLayout.js';
import AuthRequest from '../services/AuthRequest.js';


const styles = theme => ({
  form: { width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit * 1,
  },
  buttonContainer: {
    marginTop: theme.spacing.unit * 3,
  }
});


class Logout extends React.Component {
  state = {
    loading: false,
    error: null,
    client: null,
  };

  componentDidMount() {
    this.setState({loading: true, error: null});
    const { requestId } = this.props.match.params;
    AuthRequest.getLogoutRequest(requestId).then(data => {
      this.setState({
        loading: false,
        error: null,
        client: data.client,
      });
    });
  }

  handleLogoutAll = event => {
    event.preventDefault();
    this.setState({loading: true});
    AuthRequest.logout(this.props.match.params.requestId, {
      op_logout: true,
    })
      .then(data => {
        if (data.location) {
          window.location = data.location;
        } else {
          this.props.history.replace('/')
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          password: null,
          error: error.message,
        });
      })
  };

  handleLogoutApp = event => {
    event.preventDefault();
    this.setState({loading: true});
    AuthRequest.logout(this.props.match.params.requestId, {
      op_logout: false,
    })
      .then(data => {
        if (data.location) {
          window.location = data.location;
        } else {
          this.props.history.replace('/')
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          password: null,
          error: error.message,
        });
      })
  };

  render() {
    const { classes } = this.props;
    const { loading, client, error } = this.state;
    const hasError = Boolean(error);

    return (
      <Layout loading={loading}>
        {client && (
          <React.Fragment>
            <Typography align="center" variant="headline">Sign out</Typography>
            <Typography>
                Do you want to sign out of all applications,
                or only {client.client_name}?
            </Typography>
            
            <div className={classes.buttonContainer}>
              <Button onClick={this.handleLogoutAll} variant="contained" color="default" className={classes.button}>All Applications</Button>
              <Button onClick={this.handleLogoutApp} variant="contained" color="primary" className={classes.button}>Just this one</Button>
            </div>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

Logout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Logout));
