import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { withAuthContext } from './Auth.js';

import Layout from './Layout.js';
import SessionCard from './SessionCard.js';
import OidcService from '../services/OidcService.js';


const styles = theme => ({
  alert: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
});


class SessionsPage extends React.Component {
  state = {
    loading: true,
    devices: [],
  };

  async revokeSession(sessionId) {
    const { user } = this.props;
    await OidcService.revokeSession(user.access_token, sessionId);
    await this.loadSessions(); 
  };

  async loadSessions() {
    const data = await OidcService.getDeviceSessions(this.props.user.access_token);
    this.setState({
      devices: data.devices.sort((a, b) => {
        if (a.current) {
          return -1;
        }
        if (b.current) {
          return 1;
        }
        return (a.created > b.created) ? 1 : -1;
      }),
    })
  };

  async componentDidMount() {
    await this.loadSessions();
  }

  formatLocation(device) {
    if (device.city) {
      return [device.city, device.region, device.country].join(", ")
    }
    return "Unknown location"
  }

  render() {
    const { classes, theme, user } = this.props;
    const { devices } = this.state;

    return (
      <Layout>
        <Typography variant="display1" component="h1">Sessions</Typography>
        <Divider/>
        <br/>
        <Typography component="p">
            This is a list of devices that have active sessions associated
            with one or more of the accounts you are logged in with.
            Revoke any sessions that you do not recognize.
        </Typography>

        { devices.length > 0 ? devices.map((device, i) => (
          <SessionCard
              current={device.current}
              userAgent={device.user_agent.string}
              device={`${device.user_agent.user_agent.family} on ${device.user_agent.os.family} ${device.user_agent.os.major}.${device.user_agent.os.minor}.${device.user_agent.os.patch}`}
              location={`${this.formatLocation(device)} (${device.source_ip})`}
              signedIn={new Date(device.created)}
              key={i}
              revoke={() => {
                this.revokeSession(device.session_id)
              }}
          />
        )) : <LinearProgress/>}
      </Layout>
    );
  }
}

SessionsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuthContext(SessionsPage));
