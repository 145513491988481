import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'

import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { withAuthContext } from './Auth.js';

import logo from '../../assets/WP-logo-transparent.png'


class AuthMenu extends React.Component {
    state = {
        anchorEl: null,
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogout = () => {
      this.setState({anchorEl: null});
      this.props.auth.logout();
    };

    render() {
        const { user } = this.props.auth;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        if (!user) {
          return null;
        }
        return (
          <div>
            <IconButton
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem divider disabled>Signed in as {user.profile.preferred_username}</MenuItem>
              <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        );
    }
}
const AuthMenuWithRouter = withRouter(withAuthContext(AuthMenu));

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: theme.spacing.unit * 2,
  },
});

function AppBar(props) {
  const { classes, user } = props;

  return (
    <MuiAppBar position="fixed" color="default">
      <Toolbar>
        <div className={classes.grow}>
          <img src={logo} className={classes.logo}/>
        </div>
        <AuthMenuWithRouter />
      </Toolbar>
    </MuiAppBar>
  );
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppBar);
