import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';

import logo from '../../assets/WP-logo-transparent.png'


const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    position: 'relative',
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit * 4,
    height: theme.spacing.unit * 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});


class MiniLayout extends React.Component {
  render() {
    const { classes, loading } = this.props;

    return (
      <React.Fragment>
        <main className={classes.layout}>
          <div><img src={logo} className={classes.logo} /></div>
          <Paper className={classes.paper}>
            { loading && <LinearProgress className={classes.progress} /> }
            { this.props.children }
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

MiniLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MiniLayout);
