const OidcService = {

  async getDeviceSessions(accessToken) {
    const resp = await fetch("/oidc/session/devices", {
      method: "get",
      headers: new Headers({
        Authorization: `bearer ${accessToken}`,
      })
    });
    if (resp.status != 200) {
      throw new Error("An error occurred while processing the authentication request.");
    }
    return await resp.json();
  },

  async revokeSession(accessToken, sessionId) {
    const resp = await fetch("/oidc/session/" + sessionId, {
      method: "delete",
      headers: new Headers({
        Authorization: `bearer ${accessToken}`,
      })
    });
    if (resp.status != 200) {
      throw new Error("An error occurred while processing the authentication request.");
    }
    return await resp.json();
  },
};

export default OidcService
