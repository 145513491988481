import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography'

import Layout from './MiniLayout.js';


export default function LoadingPage(props) {
  const { text } = props;
  return (
    <Layout loading>
      <Typography>{ text || null }</Typography>
    </Layout>
  );
}
