import React from "react";
import { UserManager } from 'oidc-client';


/**
 * This context pair allows us to inject the AuthContext into components
 * at any level of the tree without requiring all intermediate components
 * to pass the reference through their prop chain.
 *
 * Typically these will not be used directly. The `AuthenticationGate`
 * wraps the Provider, and `withAuthContext` can be used to create a
 * component to uses the Consumer.
 */

/**
 * This is the value object that is provided and consumed by the Context.
 */
class AuthState {
  constructor({user, isPending, error, login, logout}) {
    this.user = user;
    this.isPending = isPending;
    this.error = error;
    this.login = login;
    this.logout = logout;
  }
}


const AuthContext = React.createContext();
AuthContext.Provider.displayName = "Auth.Provider";
AuthContext.Consumer.displayName = "Auth.Consumer";


function createUserManager(settings) {
  if (!settings || !settings.client_id) {
    throw new Error("OIDC client_id is required.")
  }
  const defaults = {
    authority: "https://id.wp-local.com",
    response_type: 'id_token token',
    scope: 'openid profile idp:session',
    redirect_uri: `https://${global.location.hostname}/oidc-callback-signin`,
    silent_redirect_uri: `https://${global.location.hostname}/oidc-callback-silent`,
    post_logout_redirect_uri: `https://${global.location.hostname}/oidc-callback-signout`,
    // The user info endpoint doesn't return any more info than the id token.
    loadUserInfo: false
  };
  return new UserManager({ ...defaults, ...settings });
}


export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);

    const settings = {};
    if (props.clientId) {
      settings.client_id = props.clientId;
    }
    if (props.issuer) {
      settings.authority = props.issuer;
    }
    if (props.scope) {
      settings.scope = props.scope;
    }
    this.userManager = createUserManager(settings)
  }

  state = {
    user: null,
    error: null,
    isPending: false,
    silentRefresh: false,
    isPending: true,
  }

  onSigninSuccess = (user) => {
    console.log("onSigninSuccess", user)
    this.setState({user: user, error: null, isPending: false})
  }

  onSigninError = (error) => {
    console.log("onSigninError", error)
    this.setState({error: error, isPending: false})
  }

  onSignoutSuccess = (user) => {
    console.log("onSignoutSuccess", user)
    this.setState({user: user, error: null, isPending: false})
  }

  onSignoutError = (error) => {
    console.log("onSignoutError", error)
    this.setState({error: null, isPending: false})
  }

  onUserLoaded = (user) => {
    console.log("onUserLoaded", user)
    this.setState({user: user, error: null, isPending: false})
  }

  onUserUnloaded = (user) => {
    console.log("onUserUnloaded", user)
    this.setState({user: user, error: null, isPending: false})
  }

  onAccessTokenExpiring = (user) => {
    console.log("onAccessTokenExpiring", user)
    if (this.state.silentRefresh) {
      console.log("silentRefresh already in progress.")
    } else {
      console.log("starting silentRefresh", user)
      this.setState({"silentRefresh": true})
      this.userManager.signinSilent()
    }
  }

  onAccessTokenExpired = (user) => {
    console.log("onAccessTokenExpired", user)
    this.login()
  }

  onSilentRenewError = (user) => {
    console.log("onAccessTokenExpired", user)
  }

  onUserSignedOut = (user) => {
    console.log("onUserSignedOut", user)
    this.setState({user: user, error: null, isPending: false})
  }

  login = () => {
    // Stop handling UserManager events before starting the logout. This ensures
    // that downstream components that utilize the auth state dont try to
    // re-render (or worse, re-login) while a redirect is imminent.
    this.unlisten()
    this.setState({error: null, isPending: true})
    this.userManager.signinRedirect().catch(error => this.setState({ error }))
  }

  logout = () => {
    // Stop handling UserManager events before starting the logout. This ensures
    // that downstream components that utilize the auth state dont try to
    // re-render (or worse, re-login) while a redirect is imminent.
    this.unlisten()
    this.setState({error: null, isPending: true})
    this.userManager.signoutRedirect()
  }

  handleOidcCallbacks() {
    let url;
    url = new URL(this.userManager.settings.redirect_uri)
    if (global.location.pathname == url.pathname) {
      this.setState({error: null, isPending: true});
      return this.userManager.signinRedirectCallback()
        .then(user => {
          this.setState({user: user, error: null, isPending: false})
          if (this.props.onLoginSuccess) {
            this.props.onLoginSuccess(user);
          }
        })
        .catch(error => {
          this.setState({error: error, isPending: false});
        });
    }

    if (this.userManager.settings.silent_redirect_uri) {
      url = new URL(this.userManager.settings.silent_redirect_uri)
      if (global.location.pathname == url.pathname) {
        this.setState({error: null, isPending: true});
        return this.userManager.signinSilentCallback();
      }
    }

    if (this.userManager.settings.post_logout_redirect_uri) {
      url = new URL(this.userManager.settings.post_logout_redirect_uri)
      if (global.location.pathname == url.pathname) {
        this.setState({error: null, isPending: true});
        return this.userManager.signoutRedirectCallback()
          .then(() => {
            this.setState({user: null, error: null, isPending: false})
            if (this.props.onLogoutSuccess) {
              this.props.onLogoutSuccess();
            }
          })
          .catch(error => {
            this.setState({error: error, isPending: false});
          });
      }
    }
  }

  async componentDidMount() {
    this.handleOidcCallbacks(global);

    this.userManager.getUser()
      .then(user => {this.setState({user: user, isPending: false})});
    this.userManager.events.addUserLoaded(this.onUserLoaded)
    this.userManager.events.addUserUnloaded(this.onUserUnloaded)
    this.userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring)
    this.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired)
    this.userManager.events.addSilentRenewError(this.onSilentRenewError)
    this.userManager.events.addUserSignedOut(this.onUserSignedOut)

    this.unlisten = function() {
      this.userManager.events.removeUserLoaded(this.onUserLoaded)
      this.userManager.events.removeUserUnloaded(this.onUserUnloaded)
      this.userManager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring)
      this.userManager.events.removeAccessTokenExpired(this.onAccessTokenExpired)
      this.userManager.events.removeSilentRenewError(this.onSilentRenewError)
      this.userManager.events.removeUserSignedOut(this.onUserSignedOut)
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const context = new AuthState({
      user: this.state.user,
      isPending: this.state.isPending,
      error: this.state.error,
      login: this.login,
      logout: this.logout,
    });
    return (
      <AuthContext.Provider
        value={context}
        children={this.props.children || null}
      />
    )
  }
}

/**
 * Higher Order Component function that will ensure the wrapped component
 * receives the closest `AuthContext` instance as the `auth` prop.
 */
export function withAuthContext(WrappedComponent) {
  class WithAuthContext extends React.Component {
    render() {
      return (
        <AuthContext.Consumer>
          {auth => <WrappedComponent auth={auth} {...this.props} />}
        </AuthContext.Consumer>
      );
    }
  }
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  WithAuthContext.displayName = `WithAuthContext(${displayName})`;
  return WithAuthContext;
}
