import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import Layout from './MiniLayout.js';
import AuthRequest from '../services/AuthRequest.js';


const styles = theme => ({
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});


class Login extends React.Component {
  state = {
    username: null,
    password: null,
    loading: false,
    error: null,
    client: null,
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({loading: true});
    AuthRequest.login(this.props.match.params.requestId, {
      username: this.state.username,
      password: this.state.password,
    })
      .catch(error => {
        this.setState({
          loading: false,
          password: null,
          error: error.message,
        });
      })
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      error: null,
    });
  };

  componentDidMount() {
    this.setState({loading: true, error: null});
    const { requestId } = this.props.match.params;
    AuthRequest.getRequest(requestId).then(data => {
      this.setState({
        loading: false,
        error: null,
        client: data.client,
      });
    });
  }

  render() {
    const { classes } = this.props;
    const { loading, client, error } = this.state;
    const hasError = Boolean(error);

    return (
      <Layout loading={loading}>
        {client && (
          <React.Fragment>
            <Typography align="center" variant="headline">Sign in</Typography>
            <Typography align="center">
              with your Warby Parker account<br/>
              to continue to <a href={client.client_uri}>{client.client_name}</a>
            </Typography>

            <form className={classes.form} onSubmit={this.handleSubmit}>
              <TextField
                id="username"
                autoComplete="username"
                label="Username"
                value={this.state.username || ''}
                inputProps={{
                  autoCorrect: "off",
                  autoCapitalize: "none"
                }}
                onChange={this.handleChange('username')}
                margin="normal"
                disabled={this.state.loading}
                required
                autoFocus
                fullWidth
              />
              <TextField
                id="password"
                type="password"
                autoComplete="current-password"
                label="Password"
                value={this.state.password || ''}
                onChange={this.handleChange('password')}
                margin="normal"
                disabled={this.state.loading}
                error={hasError}
                helperText={error || null}
                required
                fullWidth
              />

              <Button
                type="submit"
                fullWidth
                variant="raised"
                color="primary"
                className={classes.submit}
                disabled={this.state.loading}
              >
                Sign in
              </Button>
            </form>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Login));
