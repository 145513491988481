import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography'

import Layout from './MiniLayout.js';


export default class ErrorPage extends React.Component {
  render() {
    const { error } = this.props;
    logger.error(error); 
    return (
      <Layout>
        <Typography variant="title">Error</Typography>
        <Typography>{error.message}</Typography>
      </Layout>
    );
  }
}
