import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import Layout from './MiniLayout.js';
import AuthRequest from '../services/AuthRequest.js';


const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit * 2,
  },
});


class SelectAccount extends React.Component {
  state = {
    accounts: [],
    client: null,
    loading: false,
    error: null,
  };

  componentDidMount() {
    this.setState({loading: true, error: null});
    const { requestId } = this.props.match.params;
    AuthRequest.getRequest(requestId).then(data => {
      this.setState({
        loading: false,
        error: null,
        client: data.client,
        accounts: data.accounts,
      });
    });
  }

  handleListItemClick = (event, acct) => {
    event.preventDefault();
    this.setState({loading: true});
    AuthRequest.selectAccount(this.props.match.params.requestId, {
      sub: acct.sub,
    })
      .catch(error => {
        this.setState({
          loading: false,
          error: error.message,
        });
      })
  };

  handleAddAccount = (event) => {
    this.props.history.push('/login/' + this.props.match.params.requestId)
    event.preventDefault()
  };

  render() {
    const { classes } = this.props;
    const { loading, client, accounts, error } = this.state;
    const hasError = Boolean(error);

    return (
      <Layout loading={this.state.loading}>
        {client && (
          <React.Fragment>
            <Typography align="center" variant="headline">Choose an account</Typography>
            <Typography align="center">
              to continue to <a href={client.client_uri}>{client.client_name}</a>
            </Typography>

            <List className={classes.list}>
              {accounts.map((acct) =>
                <ListItem
                  button
                  key={acct.sub}
                  onClick={event => this.handleListItemClick(event, acct)}
                >
                  <ListItemAvatar>
                    <Avatar>?</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={acct.name}
                    secondary={`${acct.username} (via ${acct.source})`}
                  />
                </ListItem>
              )}
              <ListItem button onClick={this.handleAddAccount}>
                <ListItemAvatar>
                  <Avatar>+</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Use another account" />
              </ListItem>
            </List>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

SelectAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SelectAccount));
