import React from "react";
import { Redirect, Route, Switch } from 'react-router-dom'

import Error404 from './Error404.js';
import SessionsPage from './SessionsPage.js';
import { withAuthContext } from './Auth.js';


class UserMain extends React.Component {
  componentDidMount() {
    const { user, error, isPending, login } = this.props.auth;
    if (!isPending && !user) {
      console.log("login from UserMain")
      login();
    }
  }

  render() {
    const { user } = this.props.auth;
    if (user) {
      return (
        <Switch>
          <Route path="/user" render={() => (<Redirect to="/user/sessions" />)} exact />
          <Route path="/user/sessions" render={() => (
            <SessionsPage user={user} />
          )} />
          <Route component={Error404} />
        </Switch>
      );
    }
    return null;
  }
}

export default withAuthContext(UserMain);
