import { createMuiTheme } from '@material-ui/core/styles';

import {
  COLORS,
  SIZES,
  FONTS,
  WEIGHTS,
  IMAGE_RATIOS
} from "@warbyparker/design-constants";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.blue,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.charcoal,
      contrastText: COLORS.white,
    },
    error: {
      main: COLORS.red,
    },
    background: {
      paper: COLORS.white,
      default: COLORS.silk,
    },
    text: {
      primary: COLORS.charcoal,
    },
    divider: COLORS.silver,
  },
  typography: {
    fontFamily: FONTS.sans,
    fontSize: 16,
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: COLORS.white,
        color: COLORS.charcoal,
      },
    },
  },
});
window.theme = theme;

export default theme;
