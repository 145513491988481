import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography'

import Layout from './MiniLayout.js';


class Error404 extends React.Component {
  render() {
    return (
      <Layout>
        <Typography variant="title">404: Page not found</Typography>
      </Layout>
    );
  }
}

export default Error404;
