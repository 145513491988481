import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ComputerIcon from '@material-ui/icons/Computer';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';


const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  revokeButton: {
    marginLeft: "auto",
    marginRight: 0,
  },
})


class SessionCard extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardHeader
          title={this.props.current ? "This session" : this.props.location} /> 
        <Divider/>

        <CardContent>
          <List dense>
            <ListItem>
              <Avatar>
                <Tooltip title={this.props.userAgent}>
                  <ComputerIcon/>
                </Tooltip>
              </Avatar>
              <ListItemText primary="Device" secondary={this.props.device} />
            </ListItem>
            <ListItem>
              <Avatar>
                <LocationCityIcon/>
              </Avatar>
              <ListItemText primary="Last location" secondary={this.props.location} />
            </ListItem>
            <ListItem>
              <Avatar>
                <CalendarTodayIcon/>
              </Avatar>
              <ListItemText primary="Signed in" secondary={this.props.signedIn.toLocaleString()} />
            </ListItem>
          </List>
        </CardContent>

        {!this.props.current && 
          <React.Fragment>
            <Divider/>
            <CardActions align="right">
              <Button size="small" color="default" className={classes.revokeButton} onClick={this.props.revoke}>
                Revoke this session
              </Button>
            </CardActions>
          </React.Fragment>
        }

      </Card>
    )
  }
}

export default withStyles(styles)(SessionCard);
