import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Layout from './MiniLayout.js';
import { withAuthContext } from './Auth.js';


const styles = {};


class Home extends React.Component {
  render() {
    const { auth } = this.props;
    if (!auth.pending && auth.user) {
      return <Redirect to="/user" />
    }
    return (
      <Layout>
        <Typography align="center" variant="headline">Welcome</Typography>
        <Typography align="center">
          <Button component={Link} to="/user">Login</Button>
        </Typography>
      </Layout>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuthContext(Home));
